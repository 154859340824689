/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Action, Grid, Loading, BooleanField, DictField} from "objectum-react";
import PageTitle from "./PageTitle";
import mediator, {store} from "../modules/mediator";

function ImportCSV (props) {
	let [file, setFile] = useState (null);
	let [recs, setRecs] = useState (null);
	let [roleRecords, setRoleRecords] = useState ([]);
	let [role, setRole] = useState (null);
	let [sendEmails, setSendEmails] = useState (false);

	useEffect (() => {
		async function load () {
			let records = await store.getRecords ({model: "objectum.role"});
			setRoleRecords (records.filter (record => ["pedagog", "operator", "expert", "manager", "chief", "reserve"].indexOf (record.code) > -1));
		};
		load ();
	}, []);

	async function create ({progress}) {
		return await store.transaction (async () => {
			for (let i = 0; i < recs.length; i ++) {
				try {
					progress ({value: i + 1, max: recs.length});

					let rec = recs [i];

					if (rec.valid) {
						let empRecord = await store.createRecord ({
							_model: "emp",
							surname: rec.surname,
							forename: rec.forename,
							patronymic: rec.patronymic,
							phone: rec.phone
						});
						await store.createRecord ({
							_model: "objectum.user",
							emp: empRecord.id,
							email: rec.email,
							login: rec.snils,
							snils: rec.snils,
							password: require ("crypto").createHash ("sha1").update (rec.password).digest ("hex").toUpperCase (),
							role
						});
					}
				} catch (err) {
					throw new Error (`Ошибка в строке ${i + 1}: ${err.message}`);
				}
			}
		});
	};
	function readFile () {
		return new Promise (resolve => {
			if (file) {
				let reader = new FileReader ();

				reader.onload = function () {
					resolve (reader.result);
				};
				reader.readAsText (file, "utf-8");
			} else {
				resolve ();
			}
		});
	};
	async function load () {
		let map = ["surname", "forename", "patronymic", "email", "snils", "phone"];
		let data = await readFile ();
		let rows = data.split ("\r\n").splice (1);
		let recs = [];

		rows.forEach (row => {
			let cells = row.split (";");
			let rec = {};

			cells.forEach ((text, i) => {
				if (text) {
					rec [map [i]] = text;
				}
			});
			if (Object.keys (rec).length) {
				rec.valid = rec.surname && rec.forename && rec.email && rec.snils;

				if (rec.valid) {
					rec.password = String ((Math.random () * 8 | 0) * 1000000 + Math.random () * 1000000 | 0 + 1000000);
				}
				recs.push (rec);
			}
		});
		setRecs (recs);
		setFile (null);
	}
	return <div>
		<div className="mt-4 mb-2">
			<input type="file" className="form-control-file" accept=".csv" onChange={e => setFile (e.target.files [0])} />
		</div>
		<Action onClick={load} disabled={!file} label="Загрузить" />
		{recs && <div className="mt-2">
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>Фамилия</th>
						<th>Имя</th>
						<th>Отчество</th>
						<th>Электронная почта</th>
						<th>СНИЛС (Логин)</th>
						<th>Телефон</th>
						<th>Пароль</th>
					</tr>
				</thead>
				<tbody>
					{recs.map ((rec, i) => {
						return <tr key={i} className={rec.valid ? "table-success" : "table-danger"}>
							<td>{rec.surname}</td>
							<td>{rec.forename}</td>
							<td>{rec.patronymic}</td>
							<td>{rec.email}</td>
							<td>{rec.snils}</td>
							<td>{rec.phone}</td>
							<td>{rec.password}</td>
						</tr>;
					})}
				</tbody>
			</table>
			<DictField label="Роль" records={roleRecords} onChange={({value}) => setRole (value)} />
			<BooleanField label="Отправить письма пользователям" value={sendEmails} onChange={({value}) => setSendEmails (value)} disabled />
			<Action onClick={create} label="Добавить в БД" confirm disabled={!recs.length || !role} />
		</div>}
	</div>;
};

export default function Users (props) {
	let [refresh, setRefresh] = useState (false);
	let [loading, setLoading] = useState (true);
	let [title, setTitle] = useState ("Пользователи");
	let [params, setParams] = useState ({});
	let [query, setQuery] = useState ("user.admin");
	let [filters, setFilters] = useState ([
		["role", "=", ""],
		["post", "=", ""]
	]);
	let [declarationRecords, setDeclarationRecords] = useState ([]);
	let [declarationEmpRecords, setDeclarationEmpRecords] = useState ([]);

	useEffect (() => {
		async function load () {
			await store.getDict ("d.declaration.state");
			let records = await store.getDict ("objectum.role");
			records = records.filter (record => ["guest", "operator", "candidate", "manager", "operator", "reserve"].indexOf (record.code) == -1);
			store.map ["dict"][store.getModel ("objectum.role").id] = records;
			let declarationRecords = await store.getRecords ({model: "declaration"});
			setDeclarationRecords (declarationRecords);
			let declarationEmpRecords = await store.getRecords ({model: "t.declaration.emp"});
			setDeclarationEmpRecords (declarationEmpRecords);

			if (store.roleCode != "admin") {
				setQuery ("user.list");
				setFilters ([
					["post", "=", ""]
				]);
			}
			let title, params;

			if (store.roleCode == "pedmod" || store.roleCode == "pedObserver") {
				title = "Пользователи (педагоги)";
				params = {"role": store.dict ["objectum.role"]["pedagog"].id};
			}
			if (store.roleCode == "chiefmod") {
				title = "Пользователи (руководители, кандидаты)";
				params = {"role": [store.dict ["objectum.role"]["chief"].id, store.dict ["objectum.role"]["terrmod"].id]};
			}
			if (store.roleCode == "terrmod") {
				let terr = (await store.getRecord (mediator.record.user.emp)).terr;
				let terrRecord = await store.getRecord (terr);

				title = "Пользователи (руководители, кандидаты): " + terrRecord.name;
				params = {role: store.dict ["objectum.role"]["chief"].id, terr};
				setQuery ("user.terrmod");
			}
			if (store.roleCode == "observer") {
				let terr = (await store.getRecord (mediator.record.user.emp)).terr;

				if (terr) {
					let terrRecord = await store.getRecord (terr);

					title = "Пользователи (руководители, кандидаты): " + terrRecord.name;
					params = {role: store.dict ["objectum.role"]["chief"].id, terr};
					setQuery ("user.terrmod");
				} else {
					title = "Пользователи (руководители, кандидаты)";
					params = {"role": [store.dict ["objectum.role"]["chief"].id, store.dict ["objectum.role"]["terrmod"].id]};
				}
			}
			setTitle (title);
			setParams (params);
			setLoading (false);
		}
		load ();
	}, []);

	if (loading) {
		return <Loading />;
	}
	function onCreate () {
		props.history.push ({
			pathname: "/sapr_user/new"
		});
	}
	function onEdit ({id}) {
		props.history.push ({
			pathname: "/sapr_user/" + id
		});
	}
	async function onRemove ({id}) {
		try {
			await store.startTransaction ("Удаление пользователя: " + id);
			let userRecord = await store.getRecord (id);

			if (userRecord.emp) {
				await store.removeRecord (userRecord.emp);
			} else {
				await store.removeRecord (id);
			}
			await store.commitTransaction ();
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		setRefresh (!refresh);
	}
	function onRenderCell ({cell, col, rec}) {
		if (col.code == "info") {
			let deRecords = declarationEmpRecords.filter (record => record.emp == rec.emp);
			let dRecords = declarationRecords.filter (declarationRecord => deRecords.find (record => record.declaration == declarationRecord.id));
			let activeNum = 0, expiredNum = 0, closedNum = 0;

			dRecords.forEach (record => {
				if ([
					store.dict ["d.declaration.state"]["await"].id,
					store.dict ["d.declaration.state"]["processing"].id,
					store.dict ["d.declaration.state"]["expired"].id
				].indexOf (record.state) > -1) {
					activeNum ++;
					let date3 = new Date (record.date);
					date3.setDate (date3.getDate () + 3);

					if (record.date > date3) {
						expiredNum ++;
					}
				}
				if ([
					store.dict ["d.declaration.state"]["assigned"].id,
					store.dict ["d.declaration.state"]["rejected"].id
				].indexOf (record.state) > -1) {
					closedNum ++;
				}
			});
			return <table className="table table-sm">
				<tbody>
					<tr>
						<td className="table-success">Рассматривается <span className="badge badge-primary">{activeNum}</span></td>
						<td className="table-danger">Просрочено <span className="badge badge-primary">{expiredNum}</span></td>
						<td className="table-secondary">Закрыто <span className="badge badge-primary">{closedNum}</span></td>
					</tr>
				</tbody>
			</table>;
		} else {
			return cell;
		}
	}
	async function onDownload ({id}) {
		const docs = []
		const userRecord = await store.getRecord(id)
		const roleRecord = await store.getRecord(userRecord.role)
		const empRecord = await store.getRecord(userRecord.emp)
		const prefix = roleRecord.code == 'pedagog' ? 'p' : 'r';

		[
			['nominationFile', 'nomination'],
			['degreeFile', 'degree'],
			['rankFile', 'rank'],
			['docsFile', 'docs'],
			['confirmFile', 'confirm'],
			['drugsFile', 'drugs'],
			['programFile', 'program'],
			['jobFile', 'job'],
			['noCrimeFile', 'noCrime'],
			['nameChangeDoc', 'nameChange'],
			['categoryDoc', 'category'],
			['docList', 'docList'],
			['analyticalReport', 'analyticalReport']
		].forEach(o => {
			const [a, n] = o

			if (empRecord[a]) {
				const tokens = empRecord[a].split('.')
				const ext = tokens[tokens.length - 1]
				const name = `${prefix}_${id}_${n}.${ext}`

				docs.push ({ file: empRecord.getRef (a), name })
			}
		})
		const tables = [
			{
				model: 't.emp.crime',
				attrs: [
					['file', 'table_crime']
				]
			},
			{
				model: 't.emp.edu',
				attrs: [
					['file', 'table_edu']
				]
			},
			{
				model: 't.emp.eduActResult',
				attrs: [
					['file', 'table_eduActResult']
				]
			},
			{
				model: 't.emp.election',
				attrs: [
					['file', 'table_election']
				]
			},
			{
				model: 't.emp.exp',
				attrs: [
					['file', 'table_exp']
				]
			},
			{
				model: 't.emp.job',
				attrs: [
					['file', 'table_job']
				]
			},
			{
				model: 't.emp.personalContribution',
				attrs: [
					['file', 'table_personalContribution']
				]
			},
			{
				model: 't.emp.retraining',
				attrs: [
					['file', 'table_retraining']
				]
			},
			{
				model: 't.emp.reward',
				attrs: [
					['file', 'table_reward']
				]
			},
			{
				model: 't.emp.specialReward',
				attrs: [
					['file', 'table_specialReward']
				]
			},
			{
				model: 't.emp.studentContest',
				attrs: [
					['file', 'table_studentContest']
				]
			},
			{
				model: 't.emp.training',
				attrs: [
					['file', 'table_training']
				]
			}
		]
		for(let i = 0; i < tables.length; i ++) {
			const { model, attrs } = tables[i]
			const records = await store.getRecords({ model, filters: [['emp', '=', empRecord.id]] })

			records.forEach(record => {
				attrs.forEach(o => {
					const [a, n] = o

					if (record[a]) {
						const tokens = record[a].split('.')
						const ext = tokens[tokens.length - 1]
						const name = `${prefix}_${id}_${n}_${record.id}.${ext}`

						docs.push ({ file: record.getRef (a), name })
					}
				})
			})
		}
		let response = await fetch ("/download_docs", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify ({ docs })
		});
		let blob = await response.blob ()
		let url = window.URL.createObjectURL (blob)
		let a = document.createElement ('a')
		a.href = url
		a.download = `docs-${id}.zip`
		document.body.appendChild (a)
		a.click ()
		a.remove ()
	}
	return <div>
		<PageTitle>{title}</PageTitle>
		<Grid
			{...props} id="users" store={store}
			query={query}
			refresh={refresh}
			onRenderCell={onRenderCell}
			filters={filters}
			params={params}
		>
			<div className="d-flex align-items-center pb-1">
				{["admin", "pedmod", "chiefmod", "terrmod"].indexOf (store.roleCode) > -1 ? <Action icon="fas fa-plus" label="Добавить" onClick={onCreate} /> : null}
				<Action icon={store.roleCode == "pedObserver" ? "fas fa-eye" : "fas fa-edit"} label="Открыть" onClick={onEdit} selected />
				{["admin", "pedmod", "chiefmod"].indexOf (store.roleCode) > -1 ? <Action icon="fas fa-minus" label="Удалить" onClick={onRemove} confirm selected /> : null}
				{["admin", "pedmod", "chiefmod", "terrmod"].indexOf (store.roleCode) > -1 ? <Action icon="fas fa-file-download" label="Скачать документы" onClick={onDownload} selected /> : null}
				{store.roleCode == "admin" && <Action icon="fas fa-file-csv" label="Импорт из CSV" modalComponent={() => <ImportCSV />} />}
				{store.username == "admin" ? <Action icon="fas fa-users" label="Активность" onClick={() => {
					props.history.push ({
						pathname: "/stat"
					});
				}} /> : null}
			</div>
		</Grid>
	</div>;
};
