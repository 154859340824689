/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Action, createReport, Grid, Loading} from "objectum-react";
import PageTitle from "./PageTitle";
import mediator, {store} from "../modules/mediator";

export default function ExamEmp (props) {
	let exam = props.match.params.rid.split ("#")[0]
	let [examRecord, setExamRecord] = useState ();
	let [loading, setLoading] = useState (true);
	let [declarations, setDeclarations] = useState (null);

	useEffect (() => {
		async function load () {
			let record = await store.getRecord (exam);
			setExamRecord (record);

			let records = await store.getRecords ({
				model: "declaration",
				filters: [
					["exam", "=", exam]
				]
			});
			let chiefRecords = await store.getRecords ({
				model: "declaration",
				filters: [
					["chiefExam", "=", exam]
				]
			});
			setDeclarations ([0, ...records.map (record => record.id), ...chiefRecords.map (record => record.id)]);
			setLoading (false);
			await mediator.set ("exam", exam);
		}
		load ();
	}, [exam]);

	if (loading) {
		return <Loading />;
	}
	let query = "declaration.list";

	if (store.roleCode == "chiefmod") {
		query = "declaration.chiefmod";
	}
	if (store.roleCode == "pedmod") {
		query = "declaration.pedmod";
	}

	async function exportCSV() {
		const declarationRecords = await store.getRecs({
			model: "declaration",
			filters: [
				['id', 'in', declarations]
			]
		})
		if (!declarationRecords.length) {
			return
		}
		const empRecords = await store.getRecs({
			model: "emp",
			filters: [
				['id', 'in', declarationRecords.map(record => record.emp)]
			]
		})
		empRecords.sort ((a, b) => `${a.surname} ${a.forename}` < `${b.surname} ${b.forename}` ? -1 : 1)

/*
		let rows = [['"ID"', '"Пароль"', '"Фамилия"', '"Имя"', '"Отчество"', '"Месяц"', '"Время"', '"Дата"'].join(';')]

		empRecords.forEach(empRecord => {
			rows.push([
				'"' + empRecord.id + '"',
				'"1"',
				'"' + empRecord.surname + '"',
				'"' + empRecord.forename + '"',
				'"' + empRecord.patronymic + '"',
				'"' + examRecord.date.getMonth() + 1 + '"',
				'"' + String(examRecord.date.getHours()).padStart(2, '0') + ':' + String(examRecord.date.getMinutes()).padStart(2, '0') + '"',
				'"' + examRecord.date.getDate() + '"',
			].join(';'))
		})
		let file = new Blob ([rows.join('\r\n')], {type: "text/csv"})
		let url = URL.createObjectURL (file)
		let a = document.createElement ("a")

		a.href = url
		a.download = `exm_${examRecord.date.toLocaleDateString()}.csv`
		document.body.appendChild (a)
		a.click ()
*/
		const rows = [
			[
				{ text: 'ID' },
				{ text: 'Пароль' },
				{ text: 'Фамилия' },
				{ text: 'Имя' },
				{ text: 'Отчество' },
				{ text: 'Месяц' },
				{ text: 'Время' },
				{ text: 'Дата' },
			]
		]
		empRecords.forEach(empRecord => {
			rows.push([
				{ text: empRecord.id },
				{ text: '1' },
				{ text: empRecord.surname },
				{ text: empRecord.forename },
				{ text: empRecord.patronymic },
				{ text: examRecord.date.getMonth() + 1 },
				{ text: String(examRecord.date.getHours()).padStart(2, '0') + ':' + String(examRecord.date.getMinutes()).padStart(2, '0') },
				{ text: examRecord.date.getDate() },
			])
		})
		createReport ({
			rows,
			columns: [15, 15, 15, 15, 15, 15, 15, 15],
			font: {
				name: "Arial",
				size: 10
			},
			worksheetOpts: {
				pageSetup: {
					margins: {
						left: 0.4, right: 0.4,
						top: 0.4, bottom: 0.4,
						header: 0.1, footer: 0.1
					},
					orientation: "portrait"
				}
			},
			filename: `exm_${examRecord.date.toLocaleDateString()}.xlsx`
		});
	}

	return <div>
		<PageTitle>Участники экзамена: {examRecord?.getLabel ()}</PageTitle>
		<Grid {...props} id="declarations" store={store} query={query} params={{emp: `is not null`, archivePlace: "is null", state: "is not null", id: ` in (${declarations})`}}>
			<div className="d-flex pb-1">
{/*
				<Action icon="fas fa-edit" label="Открыть" onClick={({id}) => {
					props.history.push ({
						pathname: "/exam_declaration/" + id
					});
				}} selected />
*/}
				{store.roleCode == "observer" ? null : <div className="d-flex">
					<Action icon="fas fa-angle-double-right" label="Перемещение участников" onClick={() => {
						props.history.push ({
							pathname: "/exam_move/" + exam
						});
					}} />
					<Action icon="fas fa-file-csv" label="Экспорт Тестирование" onClick={exportCSV} />
				</div>}
			</div>
		</Grid>
	</div>;
};
