/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import React, {useReducer, useEffect} from "react";
import {Action, loadJS, BooleanField} from "objectum-react";
import crypto from "crypto";
import {store, getOptions} from "../modules/mediator";
import loginImg from "../images/login.png";

function init () {
	return {
		loading: true,
		recover: false,
		register: false,
		email: "",
		name: "",
		password: "",
		password2: "",
		inputError: {},
		options: {}
	};
}
function reducer (state, action) {
	if (action.register || action.recover) {
		return Object.assign ({}, state, action, {
			inputError: {},
			email: "",
			name: "",
			password: "",
			password2: ""
		});
	}
	return Object.assign ({}, state, action);
}
export default function Login (props) {
	let [state, dispatch] = useReducer (reducer, props, init);

	useEffect (() => {
		async function load () {
			await store.getDict ("d.doc.type")
			let docRecords = await store.getRecords ({model: "doc", filters: [['type', 'is not null']]});
			let o = {}

			docRecords.forEach(record => o[store.dict ["d.doc.type"][record.type].code] = record)
			dispatch (o)

			if (window.location.search.includes('?code=')) {
				esiaLogin()
			}
		}
		load ();
	}, []);

	useEffect (() => {
		async function init () {
			let search = document.location.search.substr (1);
			let action = {loading: false};

			if (search.startsWith ("activationId=")) {
				action.activationId = search.substr (13);
			}
			if (search.startsWith ("email=")) {
				let tokens = search.split ("&");
				let o = {};

				tokens.forEach (s => {
					let t = s.split ("=");
					o [t [0]] = t [1];
				});
				if (o.recoverId) {
					action.recoverId = o.recoverId;
					action.email = o.email;
					action.newName = o.newName;
					action.newPassword = o.newPassword;
					action.roleCode = o.roleCode;
				}
			}
			window.onRecaptchaCallback = () => {
				try {
					window.grecaptcha.render ("g-recaptcha", {
						sitekey: props.siteKey,
						callback: res => dispatch ({recaptchaRes: res}),
						theme: "light"
					});
				} catch (err) {
				}
			};
			if (action.activationId) {
				try {
					let result = await store.remote ({
						model: "admin",
						method: "activation",
						activationId: action.activationId
					});
					await store.transaction (async () => {
						let records = await store.getRecords ({model: "objectum.user", filters: [["login", "=", result.login]]});
						//records [0].login = records [0].snils;
						//await records [0].sync ();

						await store.remote ({
							model: "admin",
							method: "updateUser",
							userId: records [0].id,
							login: records [0].snils
						})
					});
					action.activationResult = "Учетная запись активирована";
				} catch (err) {
					action.activationResult = err.message;
				}
				if (window.history) {
					let url = window.location.protocol + "//" + window.location.host + window.location.pathname;
					window.history.pushState ({path: url}, "", url);
				}
				action.activationId = "";
			}
			if (action.recoverId) {
				try {
					await store.remote ({
						model: "admin",
						method: "recover",
						recoverId: action.recoverId,
						email: action.email,
						newPassword: action.newPassword,
						newName: action.newName,
						roleCode: action.roleCode
					});
					action.recoverResult = "Пароль изменен";
				} catch (err) {
					action.recoverResult = err.message;
				}
				if (window.history) {
					let url = window.location.protocol + "//" + window.location.host + window.location.pathname;
					window.history.pushState ({path: url}, "", url);
				}
				action.recoverId = "";
			}
			action.options = await getOptions ()
			await store.getDict ("objectum.role");
			dispatch (action);
		}
		init ();
	}, [props.siteKey]);

	let recover = state.recover, register = state.register;

	useEffect (() => {
		try {
			window.grecaptcha.render ("g-recaptcha", {
				sitekey: props.siteKey,
				callback: (res) => {
					dispatch ({recaptchaRes: res})
				},
				theme: "light"
			});
			dispatch ({recaptchaRendered: true});
		} catch (err) {
		}
	}, [recover, register, props.siteKey]);

	function onChange (val) {
		let id = val.target.id;
		let value = val.target.value;

		if (id == "email") {
			value = value.trim ().toLowerCase ();
		}
		if (id == "snils") {
			value = value || "";
			let s = "";
			for (let i = 0; i < value.length; i ++) {
				if ("0123456789-".includes (value [i])) {
					if (value [i] == '-' && i !== 3 && i !== 7 && i !== 11) {
						continue;
					}
					s += value [i]
				}
			}
			if (s.length > 14) {
				s = s.substr (0, 14);
			}
			value = s;

			if (/^[0-9]{3}$/.test (value) || /^[0-9]{3}-[0-9]{3}$/.test (value) || /^[0-9]{3}-[0-9]{3}-[0-9]{3}$/.test (value)) {
				value = value + "-";
			}
		}
		dispatch ({[id]: value});
	}
	async function onRegister () {
		let activationHost = document.location.protocol + "//" + document.location.host + document.location.pathname;
		let fields = ["email", "snils", "personalDataConsentValue", "admRegulationsPedagogValue",  "listIndicatorsPedagogValue",  "recaptchaRes"];

		dispatch ({inputError: {}});

		for (let i = 0; i < fields.length; i ++) {
			if (!state [fields [i]]) {
				return dispatch ({inputError: {[fields [i]]: "Пожалуйста введите значение"}});
			}
			if (fields [i] == "snils" && !(/^[0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{2}$/.test (state.snils))) {
				return dispatch ({inputError: {[fields [i]]: "Пример: 111-111-111-11"}});
			}
		}
/*
		if (state.password != state.password2) {
			return dispatch ({inputError: {password: "Введенные пароли не совпадают"}});
		}
*/
		let records = await store.getRecords ({
			model: "objectum.user",
			filters: [
				["snils", "=", state.snils],
				["role", "=", store.dict ["objectum.role"]["pedagog"].id]
			]
		});
		if (records.length) {
			return dispatch ({inputError: {snils: "Введенный СНИЛС уже используется"}});
		}
		records = await store.getRecords ({model: "objectum.user", filters: [["email", "=", state.email]]});

		if (records.length) {
			return dispatch ({inputError: {snils: "Введенный адрес электронной почты уже используется"}});
		}
		let password = String (1000000 + (Math.random () * 1000000 | 0) * ((Math.random () * 8 + 1) | 0));
		let result = await store.remote ({
			model: "admin",
			method: "register",
			activationHost,
			email: state.email,
			password: crypto.createHash ("sha1").update (password).digest ("hex").toUpperCase (),
			name: state.name,
			subject: "Регистрация пользователя",
			text: `Ваш пароль: ${password}. Для активации учетной записи, нажмите на ссылку`,
			recaptchaRes: state.recaptchaRes
		});
		let userRecords = await store.getRecords ({model: "objectum.user", filters: [["email", "=", state.email]]});
		await store.remote ({
			model: "admin",
			method: "updateUser",
			userId: userRecords [0].id,
			snils: state.snils,
			regDate: new Date ()
		})
/*
		await store.transaction (async () => {
			let records = await store.getRecords ({model: "objectum.user", filters: [["email", "=", state.email]]});
			let record = records [0];
			record.snils = state.snils;
			record.regDate = new Date ();
			await record.sync ();
		});
*/
		return result;
	}
	async function onLogin () {
		let fields = ["email", "password"];

		for (let i = 0; i < fields.length; i ++) {
			if (!state [fields [i]]) {
				return dispatch ({inputError: {[fields [i]]: "Пожалуйста введите значение"}});
			}
		}
		try {
			await store.auth ({
				username: state.email,
				password: require ("crypto").createHash ("sha1").update (state.password).digest ("hex").toUpperCase ()
			});
			dispatch ({authorized: true, inputError: {}});
			props.history.push ({
				pathname: "/office"
			});
//			return "Вход выполнен"
		} catch (err) {
			if (err.message == "401 Unauthenticated") {
				dispatch ({error: "Неправильный логин (СНИЛС) или пароль", inputError: {}});
			} else {
				dispatch ({error: err.message, inputError: {}});
			}
		}
	}
	async function onKeyDown (e) {
		if (e.key === "Enter" && state.email && state.password) {
			await onLogin ();
		}
	}
	async function onRecover () {
		let activationHost = document.location.protocol + "//" + document.location.host + document.location.pathname;
		let fields = ["email", "password", "password2"];

		for (let i = 0; i < fields.length; i ++) {
			if (!state [fields [i]]) {
				return dispatch ({inputError: {[fields [i]]: "Пожалуйста введите значение"}});
			}
		}
		if (state.password != state.password2) {
			return;
		}
		dispatch ({inputError: {}});

		return await store.remote ({
			model: "admin",
			method: "recoverRequest",
			activationHost,
			email: state.email,
			name: state.name || undefined,
			password: require ("crypto").createHash ("sha1").update (state.password).digest ("hex").toUpperCase (),
			subject: "Восстановление пароля",
			text: "Для восстановления пароля, нажмите на ссылку",
			recaptchaRes: state.recaptchaRes,
			roleCode: window.location.search.includes('chief=1') ? 'chief' : 'pedagog'
		});
	}
	async function switchMode ({register, recover}) {
		if (register || recover) {
			await loadJS ("https://www.google.com/recaptcha/api.js?onload=onRecaptchaCallback&render=explicit");
		}
		dispatch ({register, recover, inputError: {}, email: "", name: "", password: "", password2: ""});
	}
	async function esia () {
		let url = await store.remote ({
			model: "admin",
			method: "getEsiaUrl",
			host: document.location.protocol + "//" + document.location.host
		});
		document.location.href = url
	}
	async function esiaLogin () {
		dispatch ({error: "Авторизация через ЕСИА ...", inputError: {}})

		const urlParams = new URLSearchParams(window.location.search);
		let data = await store.remote ({
			model: "admin",
			method: "esiaLogin",
			code: urlParams.get('code')
		})
		if (data.snils && data.password) {
			await store.auth ({
				username: data.snils,
				password: data.password
			})
			dispatch ({authorized: true, inputError: {}})
			props.history.push ({
				pathname: "/office"
			})
		} else {
			dispatch ({error: "Не удалось авторизоваться через ЕСИА", inputError: {}})
		}
	}
	let content;

	if (state.loading) {
		return null;
	}
	if (props.authorized || state.authorized) {
		content = props.children;
	} else if (state.activationId) {
		content = <div className="p-3 shadow">
			{"Активация учеьной записи ..."}
		</div>;
	} else if (state.recoverId) {
		content = <div className="p-3 shadow">
			Восстановление пароля ...
		</div>;
	} else if (state.activationResult) {
		content = <div className="p-3 shadow">
			<div className="mb-2">
				{state.activationResult}
			</div>
			<Action
				onClick={() => dispatch ({activationResult: ""})}
			><i className="fas fa-check mr-2"/>Ok</Action>
		</div>;
	} else if (state.recoverResult) {
		content = <div className="p-3 shadow">
			<div className="mb-2">
				{state.recoverResult}
			</div>
			<Action
				onClick={() => dispatch ({recoverResult: ""})}
			><i className="fas fa-check mr-2"/>Ok</Action>
		</div>;
	} else if (state.recover) {
		content = <div className="row">
			<div className="col-4">
				<h3 className="text-center">Восстановление пароля</h3>
				<div className="form-group mt-4">
					<label htmlFor="email">Электронная почта</label>
					<input type="email" className="form-control" id="email" value={state.email} onChange={onChange} />
					{state.inputError ["email"] && <div className="small text-danger">{state.inputError ["email"]}</div>}
				</div>
				<div className="form-group">
					<label htmlFor="password">Новый пароль</label>
					<input type="password" className="form-control" id="password" value={state.password} onChange={onChange} />
					{state.inputError ["password"] && <div className="small text-danger">{state.inputError ["password"]}</div>}
				</div>
				<div className="form-group">
					<label htmlFor="password2">Еще раз</label>
					<input type="password" className="form-control" id="password2" value={state.password2} onChange={onChange} />
					{state.inputError ["password2"] && <div className="small text-danger">{state.inputError ["password2"]}</div>}
				</div>
				{state.password && state.password2 && state.password != state.password2 && <div className="small text-danger mb-2">
					Пароли не совпадают
				</div>}
				<div id="g-recaptcha" />
				{state.inputError ["recaptchaRes"] && <div className="small text-danger">{state.inputError ["recaptchaRes"]}</div>}
				<div className="text-center">
					<div className="mt-3">
						<Action
							btnClassName="btn btn-primary sapr-auth-button px-0"
							onClick={onRecover}
						><i className="fas fa-envelope mr-2"/>Восстановление пароля</Action>
					</div>
					<div className="mt-3">
						<button className="btn btn-outline-primary px-0 sapr-auth-button" onClick={() => switchMode ({register: false, recover: false})}>
							<i className="fas fa-sign-in-alt mr-2"/>Вход
						</button>
					</div>
				</div>
			</div>
			<div className="col">
				<img src={loginImg} alt="" style={{width: "100%"}} />
			</div>
		</div>;
	} else if (state.register) {
		const agreed1 = state.personalDataConsent
			? <a target="_blank" rel="noopener noreferrer" href={state.personalDataConsent.getRef ("file")} onClick={() => dispatch({ personalDataConsentClicked: true })} className="text-danger"><u>Согласие на обработку</u></a>
			: <div className="text-danger">Файл отсутствует. Добавьте файл в "Документы"</div>
		const agreed2 = state.admRegulationsPedagog
			? <a target="_blank" rel="noopener noreferrer" href={state.admRegulationsPedagog.getRef ("file")} onClick={() => dispatch({ admRegulationsPedagogClicked: true })} className="text-danger"><u>Ознакомлен с Административным регламентом</u></a>
			: <div className="text-danger">Файл отсутствует. Добавьте файл в "Документы"</div>
		const agreed3 = state.listIndicatorsPedagog
			? <a target="_blank" rel="noopener noreferrer" href={state.listIndicatorsPedagog.getRef ("file")} onClick={() => dispatch({ listIndicatorsPedagogClicked: true })} className="text-danger"><u>Ознакомлен с Перечнем критериев и показателей</u></a>
			: <div className="text-danger">Файл отсутствует. Добавьте файл в "Документы"</div>

		content = <div className="row">
			<div className="col-4">
				<h3 className="text-center">Регистрация педагога</h3>
				<div className="form-group mt-4">
					<label htmlFor="email">Электронная почта</label>
					<input type="email" className="form-control" id="email" value={state.email} onChange={onChange} />
					{state.inputError ["email"] && <div className="small text-danger">{state.inputError ["email"]}</div>}
				</div>
				<div className="form-group">
					<label htmlFor="snils">СНИЛС</label>
					<input className="form-control" id="snils" value={state.snils} onChange={onChange} />
					{state.inputError ["snils"] && <div className="small text-danger">{state.inputError ["snils"]}</div>}
				</div>
				<div className="border my-2 p-2 rounded">
					<BooleanField label={<small>{state.options.pedagogConfirm || agreed1} персональных данных</small>}
						value={state.personalDataConsentValue} onChange={({value}) => dispatch ({personalDataConsentValue: value})} disabled={!state.personalDataConsentClicked} />
					{state.inputError ["personalDataConsentValue"] && <div className="small text-danger mb-2">{state.inputError ["personalDataConsentValue"]}</div>}
				</div>
				<div className="border my-2 p-2 rounded">
					<BooleanField label={<small>{agreed2} по предоставлению государственной услуги «Аттестация педагогических работников организаций, осуществляющих образовательную деятельность на территории РД, в целях установления квалификационной категории»</small>}
								  value={state.admRegulationsPedagogValue} onChange={({value}) => dispatch ({admRegulationsPedagogValue: value})} disabled={!state.admRegulationsPedagogClicked} />
					{state.inputError ["admRegulationsPedagogValue"] && <div className="small text-danger mb-2">{state.inputError ["admRegulationsPedagogValue"]}</div>}
				</div>
				<div className="border my-2 p-2 rounded">
					<BooleanField label={<small>{agreed3} для оценки профессиональной деятельности педагогических работников</small>}
								  value={state.listIndicatorsPedagogValue} onChange={({value}) => dispatch ({listIndicatorsPedagogValue: value})} disabled={!state.listIndicatorsPedagogClicked} />
					{state.inputError ["listIndicatorsPedagogValue"] && <div className="small text-danger mb-2">{state.inputError ["listIndicatorsPedagogValue"]}</div>}
				</div>
{/*
				<div className="form-group">
					<label htmlFor="password">Пароль</label>
					<input type="password" className="form-control" id="password" value={state.password} onChange={onChange} />
					{state.inputError ["password"] && <div className="small text-danger">{state.inputError ["password"]}</div>}
				</div>
				<div className="form-group">
					<label htmlFor="password2">Еще раз</label>
					<input type="password" className="form-control" id="password2" value={state.password2} onChange={onChange} />
					{state.inputError ["password2"] && <div className="small text-danger">{state.inputError ["password2"]}</div>}
				</div>
				{state.password && state.password2 && state.password != state.password2 && <div className="small text-danger mb-2">
					Введенные пароли не совпадают
				</div>}
*/}
				<div id="g-recaptcha" />
				{state.inputError ["recaptchaRes"] && <div className="small text-danger">{state.inputError ["recaptchaRes"]}</div>}
				<div className="text-center">
					{!state.options.pedagogRegDisabled && <div className="mt-3">
						<Action
							btnClassName="btn btn-primary sapr-auth-button"
							onClick={onRegister}
						><i className="fas fa-key mr-2"/>Регистрация педагога</Action>
					</div>}
					<div className="mt-3">
						<button className="btn btn-outline-primary px-0 sapr-auth-button" onClick={() => switchMode ({register: false, recover: false})}>
							<i className="fas fa-sign-in-alt mr-2"/>Вход
						</button>
					</div>
				</div>
			</div>
			<div className="col">
				<img src={loginImg} alt="" style={{width: "100%"}} />
			</div>
		</div>;
	} else {
		content = (
            <div className="row">
                <div className="col-4">
                    <h3 className="text-center">Вход</h3>
                    <div className="form-group mt-4">
                        <label htmlFor="email">Имя пользователя (СНИЛС)</label>
                        <input
                            type="email"
                            autoFocus
                            className="form-control"
                            id="email"
                            value={state.email}
                            onChange={onChange}
                        />
                        {state.inputError['email'] && (
                            <div className="small text-danger">{state.inputError['email']}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="loginPassword">Пароль</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={state.password}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                        />
                        {state.inputError['password'] && (
                            <div className="small text-danger">{state.inputError['password']}</div>
                        )}
                    </div>
                    <div className="text-center">
                        <div className="mt-3">
                            <button
                                className="btn btn-link p-0 m-0 sapr-auth-button font-italic"
                                onClick={() => switchMode({ register: false, recover: true })}
                            >
                                Забыли пароль?
                            </button>
                        </div>
                        <div className="mt-4">
                            <Action btnClassName="btn btn-primary sapr-auth-button" onClick={onLogin}>
                                <i className="fas fa-sign-in-alt mr-2" />
                                Вход
                            </Action>
                        </div>
                        {document.location.href.indexOf('chief=1') == -1 && !state.options.pedagogRegDisabled && (
                            <div className="mt-3">
                                <button
                                    className="btn btn-outline-primary px-0 sapr-auth-button"
                                    onClick={() => switchMode({ register: true, recover: false })}
                                >
                                    <i className="fas fa-key mr-2" />
                                    Регистрация педагога
                                </button>
                                <button
                                    className="btn btn-outline-primary px-0 sapr-auth-button mt-3"
                                    onClick={() => esia()}
                                >
                                    <i className="fas fa-key mr-2" />
                                    Вход через ЕСИА
                                </button>
                            </div>
                        )}
                    </div>
                    {state.error && (
                        <div className="mt-3 alert alert-danger" role="alert">
                            {state.error}
                        </div>
                    )}
                </div>
                <div className="col">
                    <img src={loginImg} alt="" style={{ width: '100%' }} />
                </div>
            </div>
        )
	}
	return <div className="">{content}</div>;
};
